<!-- 出库单记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 出库单号 -->
      <FormItem>
        <span>出库单号：</span>
        <Input placeholder="请输入" v-model="queryFrom.order_number" class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 客户名称 -->
      <FormItem class="marginLeft40">
        <span>客户名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.customer_id" clearable filterable class="iviewIptWidth250" @on-change="customerChange" label-in-value>
          <Option v-for="(item, index) in customerList" :value="item.customer_id" :key="index">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="marginLeft40">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <FormItem class="marginLeft40" style="margin-right: 30px !important;">
        <span>产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" clearable filterable placeholder="请选择" ref="name_select" v-model="queryFrom.product_id">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index" :label="item.product_name">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 出库仓库 -->
      <FormItem class="marginLeft40">
        <span>出库仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 出库时间 -->
      <FormItem class="marginLeft40">
        <span>出库时间：</span>
        <DatePicker type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeDate"></DatePicker>
      </FormItem>
      <!-- 经手人 -->
      <FormItem class="marginLeft40">
        <span>经手人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in userList" :value="item.user_name" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>回单：</span>
        <Select placeholder="请选择" v-model="queryFrom.is_back_proof" clearable filterable class="iviewIptWidth250">
          <Option :value="1">是</Option>
          <Option :value="2">否</Option>
        </Select>
      </FormItem>
      <!-- <FormItem class="marginLeft40">
        <span>厂家名称：</span>
        <Input placeholder="请输入" v-model="queryFrom.factory_name" class="iviewIptWidth250"></Input>
      </FormItem> -->
      <FormItem class="marginLeft40">
        <span class="label">厂家名称：</span>
        <Select filterable clearable v-model="queryFrom.factory_name" class="iviewIptWidth250">
          <Option v-for="item in nameList" :value="item.name" :key="item.subfactory_id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="queryList(true)">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spin style="display: inline-block;" v-show="Loading">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          合并导出
        </span>
        <span class="longTextBtn finger btnReset marginLeft20" @click="downTemplate">模板下载</span>
        <span class="longTextBtn finger btnReset marginLeft20" v-if="!disblesImg">
          <FormUploadFile label="模板导入" name="xlsx_file" :multiple="false" :apiSrc="apiSrc" @get-data="importTemplate"></FormUploadFile>
        </span>
        <span class="longTextBtn finger btnReset marginLeft20" v-if="disblesImg" @click="disableClick">模板导入</span>
      </FormItem>
    </Form>
    <!-- <CustomTable :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录"></CustomTable> -->

    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :columns="listColumns" :data="listData" highlight-row border :row-class-name="rowClassName" @on-sort-change="changeSort">
        <template slot-scope="{ row, index }" slot="jujiao">
          <div class="changeClass">
            <div v-show="row.jujiao" @click="clickBeiZhu(index, row.id, row.note)">
              {{ row.note ? row.note : '-' }}
            </div>
            <div v-if="!row.jujiao">
              <Input placeholder="请输入" :ref="'input' + row.warehouse_receipt_id" v-model.trim="changeNote" @on-blur="changeNoteFun(row)" :autofocus="1 == 1"></Input>
            </div>
          </div>
        </template>
      </Table>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="queryFrom.page && total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <Page v-if="queryFrom.page && total > 60" :total="total" :current="queryFrom.page" @on-change="changePage" show-elevator class="fl" />
        <div class="totalNumber margin0">
          <div class="item">
            总数量：<span class="color389 marginRight20">{{ total_number }}</span>
          </div>
          <div class="item">
            总金额：<span class="color389 marginRight20">¥{{ $utils.formatMoney(total_amount) }}</span>
          </div>
          <div class="item">共计{{ total }}条记录</div>
        </div>
        <!-- <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div> -->
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !chaxunFlag">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <div v-if="chaxunFlag" style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData">
        <img style="margin-top: 100px;" src="../../assets/images/erpNoData.png" />
        <p class="noDataImg">请选择查询条件进行查询</p>
      </div>
    </div>

    <!-- 导入确认-弹窗 -->
    <Modal v-model="analytical" :mask-closable="false" :footer-hide="true" width="90" class="modal">
      <p slot="header" class="header">导入确认</p>
      <div class="body">
        <Form inline class="formMarginBtm10">
          <FormItem>
            <span>购买单位：</span>
            <Select placeholder="请选择" v-model.trim="analyticalTopData.customer_name" clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in customerList" :value="item.customer_name" :key="index">{{ item.customer_name }}</Option>
            </Select>
          </FormItem>
          <!-- 出库仓库 -->
          <FormItem class="marginLeft10">
            <span>出库仓库：</span>
            <Input readonly v-model.trim="analyticalTopData.warehouse_name" class="iviewIptWidth200"></Input>
            <!-- <Select placeholder="请选择" v-model="analyticalTopData.warehouse_id" clearable filterable class="iviewIptWidth200" @on-change="warehouseIdChange">
              <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
            </Select> -->
          </FormItem>
          <FormItem class="marginLeft10">
            <span>订单类型：</span>
            <Select placeholder="请选择" v-model="order_type" transfer clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in orderTypeList" :value="item.type" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft10">
            <span>销售人：</span>
            <Select placeholder="请选择" v-model="analyticalTopData.sale_by" transfer clearable filterable class="iviewIptWidth200">
              <Option v-for="(item, index) in PublicSpecialUser" :value="item.id" :key="index">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="marginLeft10">
            <span>备注：</span>
            <Input placeholder="请输入" v-model.trim="analyticalTopData.note" class="iviewIptWidth200"></Input>
          </FormItem>
          <FormItem>
            <span>订单日期：</span>
            <DatePicker type="date" :disabled="RoleId == 1 ? false : true" placeholder="请选择订单日期" @on-change="changeTime" v-model="analyticalTopData.order_time" class="iviewIptWidth180"></DatePicker>
          </FormItem>
        </Form>
        <Table :height="450" :columns="analyticalList" :data="analyticalData" border>
          <template slot-scope="{ row, index }" slot="quantity">
            <!-- <Input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeInput($event, 'quantity', index)"></Input> -->
            <InputNumber :max="999999" :min="0" :precision="0" v-model="row.quantity" @on-change="changeInput($event, 'quantity', index)"></InputNumber>
          </template>
          <template slot-scope="{ row, index }" slot="unit_price">
            <!-- <Input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeInput($event, 'quantity', index)"></Input> -->
            <InputNumber :max="999999" :min="0" v-model="row.unit_price" @on-change="changeInput($event, 'unit_price', index)"></InputNumber>
          </template>
          <template slot-scope="{ row, index }" slot="sale_tax_rate">
            <!-- <Input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeInput($event, 'quantity', index)"></Input> -->
            <InputNumber :max="999999" :min="0" :precision="2" v-model="row.sale_tax_rate" @on-change="changeInput($event, 'sale_tax_rate', index)"></InputNumber>
            <span style="marginleft: 5px;">%</span>
          </template>
          <template slot-scope="{ row, index }" slot="batch_number">
            <Input placeholder="请输入" :value="row.batch_number" @on-change="changeInput($event, 'batch_number', index)"></Input>
          </template>

          <template slot-scope="{ row, index }" slot="serial_number">
            <Input placeholder="请输入" :value="row.serial_number" @on-change="changeInput($event, 'serial_number', index)"></Input>
          </template>
          <!-- 生产日期 -->
          <template slot-scope="{ row, index }" slot="production_date">
            <Input @on-blur="dataBlur($event, true)" :value="row.production_date" placeholder="请输入" style="width: 200px;" @on-change="changeInput($event, 'production_date', index)" />
            <!-- <DatePicker placeholder="请输入" type="date" format="yyyy-MM-dd" :transfer="true" :value="row.production_date" @on-change="changeInput($event, 'production_date', index)"></DatePicker> -->
          </template>
          <!-- 有效期/失效期 -->
          <template slot-scope="{ row, index }" slot="valid_period">
            <Input @on-blur="dataBlur($event)" :value="row.valid_period" placeholder="请输入" style="width: 200px;" @on-change="changeInput($event, 'valid_period', index)" />
            <!-- <DatePicker placeholder="请输入" type="date" format="yyyy-MM-dd" :transfer="true" :value="row.valid_period" @on-change="changeInput($event, 'valid_period', index)"></DatePicker> -->
          </template>
        </Table>
        <div class="totalNumber">
          <div class="item">
            总数量:<span class="color389 marginRight20">{{ TotalQuantity }}</span>
          </div>
          <div class="item">
            总金额:<span class="color389 marginRight20">￥ {{ TotalMoney }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="analytical = false">取消</span>
        <!-- <span class="pageBtn finger marginLeft20 btnSure" @click="sureAnalytical">确定</span> -->
        <span class="pageBtn finger marginLeft20 btnSure">
          <Spin v-if="loadingFlag" style="display: inline-block; color: white;">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          <span v-if="!loadingFlag" @click="sureAnalytical">确定</span>
        </span>
      </div>
    </Modal>

    <!-- 反冲确认-弹窗 -->
    <Modal v-model="isConfirm[0]" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>反冲</p>
          <p>是否确认反冲</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="isConfirm = [false, undefined]">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="recoil(isConfirm[1])">确定</span>
      </div>
    </Modal>
    <!-- 判断弹窗里面数据是否为0-弹窗 -->
    <Modal v-model="isZero" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="fl overF">
          <p style="margintop: 5px;" v-for="(item, index) in isZeroNotice" :key="index" :value="item">
            {{ item }}
          </p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="isZero = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="isZero = false">确定</span>
      </div>
    </Modal>
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
// import CustomTable from '@/components/table'
import FormUploadFile from '@/components/formUploadFile.vue'
import http from '@/service/baseUrl'

export default {
  name: 'warehouseReceiptRecord',
  components: {
    // CustomTable,
    FormUploadFile,
  },
  // 计算金额数量
  computed: {
    //  总数量
    TotalQuantity() {
      let total = 0
      this.analyticalData.forEach(item => {
        total = total + item.quantity
      })
      return total
    },
    // 总金额
    TotalMoney() {
      let total = 0
      this.analyticalData.forEach(item => {
        total = total + item.unit_price * item.quantity
      })
      return total.toFixed(2)
    },
  },
  data() {
    return {
      sort_field: '',
      sort_type: '',
      loadingFlag: false,
      isZeroNotice: [],
      isZero: false,
      chaxunFlag: true,
      isZeroNotice: [],
      isZero: false,
      tabloading: false,
      modelList: [],
      productList: [],
      billNumber: '',
      Loading: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 6,
      typeList: [],
      status: false,
      // 待确认头部数据
      analyticalTopData: {},
      // 待确认表格数据
      analyticalData: [],
      // 待确认表头
      analyticalList: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 55,
          maxWidth: 90,
        },
        {
          title: '产品编码',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 300,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单价(¥)',
          slot: 'unit_price',
          align: 'center',
          minWidth: 100,
        },
        // {
        //   title: '单价(¥)',
        //   key: 'unit_price',
        //   align: 'center',
        //   minWidth: 150,
        //   render: (h, param) => {
        //     return h('span', this.$utils.formatMoney(param.row.unit_price, true))
        //   },
        // },
        {
          title: '销售税率(%)',
          slot: 'sale_tax_rate',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '金额',
          key: 'amount',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '生产日期',
          slot: 'production_date',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '有效期/失效期',
          slot: 'valid_period',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
          maxWidth: 350,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '注册证有效期',
          key: 'licence_valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '储运条件',
          key: 'storage_condition',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.addAnalytical(param.index)
                    },
                  },
                },
                '新增'
              ),
              h(
                'span',
                {
                  class: 'tableFont marginLeft20',
                  on: {
                    click: () => {
                      this.delAnalytical(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      // 订单类型
      order_type: '',
      // 订单类型下拉
      orderTypeList: [
        { name: '普通购销', type: '1' },
        { name: '自寄售', type: '2' },
        { name: '供方寄售', type: '3' },
        { name: '委托代销', type: '4' },
      ],
      // 销售人下拉
      PublicSpecialUser: [],
      // 导入确认弹窗
      analytical: false,
      // 导入接口
      apiSrc: '',

      // 反冲确认弹窗 + 出库单id
      isConfirm: [false, undefined],
      // 总条数
      total: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          // maxWidth: 90,
        },
        {
          title: '出库单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120,
          // maxWidth: 300,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 250,
          // maxWidth: 300,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.customer_name
              ),
            ])
          },
        },
        {
          title: '出库仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '出库时间',
          key: 'create_time',
          align: 'center',
          minWidth: 120,
          // maxWidth: 300,
        },
        {
          title: '经手人',
          key: 'create_by',
          align: 'center',
          minWidth: 80,
          // maxWidth: 300,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 100,
          // maxWidth: 120,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 120,
          // maxWidth: 180,
          render: (h, param) => {
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h(
              'div',
              {},
              // param.row.total_amount
              str
            )
          },
        },
                {
          title: '备注',
          // key: 'note',
          slot: 'jujiao',
          sortable: 'custom',
          align: 'center',
          minWidth: 120,
          // maxWidth: 140,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'div',
          //       {
          //         class: 'TextAlign_lf',
          //       },
          //       param.row.note
          //     ),
          //   ])
          // },
        },
        // {
        //   title: '备注',
        //   key: 'note',
        //   sortable: 'custom',
        //   align: 'center',
        //   minWidth: 120,
        //   maxWidth: 140,
        //   render: (h, param) => {
        //     return h('div', [
        //       h(
        //         'div',
        //         {
        //           class: 'TextAlign_lf',
        //         },
        //         param.row.note
        //       ),
        //     ])
        //   },
        // },
        {
          title: '回单',
          key: 'note',
          align: 'center',
          minWidth: 80,
          maxWidth: 100,
          render: (h, param) => {
            let is_back_proof = param.row.is_back_proof;
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: is_back_proof == 1 ? '#56dd6e' : '#fa3e3b',
                    cursor: 'pointer'
                  },
                  on: {
                    click: (is_back_proof) => {
                      this.up_is_back_proof(param)
                    },
                  },
                },
                is_back_proof == 1?'是':'否'
              ),
            ])
          },
        },
        {
          title: '打印次数',
          key: 'prints_num',
          sortable: 'custom',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 170,
          maxWidth: 250,
          fixed: 'right',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    marginLeft: '10px',
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.output(param.row.order_number)
                    },
                  },
                },
                '导出'
              ),
              h(
                'span',
                {
                  style: {
                    marginLeft: '10px',
                    display: param.row.recoil_status == 1 ? 'none' : 'inline',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      if (this.disblesImg) {
                        this.$Message.warning('当月已关账，不可操作库存')
                        return
                      }
                      this.isConfirm = [true, param.row.id]
                    },
                  },
                },
                '反冲'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 || param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '10px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.clickId = param.row.id
                      this.status = true
                      // 获取单据编号
                      this.billNumber = param.row.order_number
                      this.getTemplate()
                    },
                  },
                },
                '打印'
              ),
            ])
          },
        },
      ],
      clickId: null,
      // 表单拉下选项
      thirdList: [],
      // 客户下拉
      customerList: [],
      // 出库仓库下拉
      warehouseList: [],
      // 经手人
      userList: [],
      // 查询参数
      queryFrom: {
        product_code: '',
        page: 1,
        rows: 10,
        order_number: '',
        customer_id: undefined,
        customer_type: '',
        warehouse_id: undefined,
        create_time_start: '',
        create_time_end: '',
        create_by: '',
        product_id: '',
        product_code_array: [],
        specification_id_str: '',
        factory_name:'',
        is_back_proof:''
      },
      paging: null,
      // 暂存数据
      storageData: [],
      disblesImg: false,
      total_amount: 0,
      total_number: 0,
      RoleId: '',
      nameList:[],
      listDataNote: '',
      listDataIndex: '',
      rowWarehouse_receipt_id: '',
      changeNote: '',
    }
  },
  created() {
    this.apiSrc = http.http + this.$api.deliveryTemplateImport.url
    this.getListSupList();
  },
  mounted() {
    // this.queryList()
    this.getCustomerList()
    this.getWarehouseList()
    this.getUserList()
    this.getPublicSpecialUser()
    this.queryProductSelect(null)
    this.queryProductCodeSelect(null)
    this.inventoryLook()
    // 导入模板的时间只能让admin操作
    this.RoleId = localStorage.getItem('RoleId')
    console.log(this.RoleId, 'RoleId')
  },
  activated() {
    this.inventoryLook()
  },
  methods: {
    // 获取厂家下拉列表
    getListSupList() {
      this.$http.get(this.$api.factoryListBusiness, true).then(res => {
        this.nameList = res.data
      })
    },
    changeSort(column) {
      if (column.column.title == '备注') {
        this.sort_field = 'note'
      } else if (column.key && column.key == 'prints_num') {
        this.sort_field = 'prints_num'
      }
      if (column.order == 'normal') {
        this.sort_type = ''
      } else {
        this.sort_type = column.order
      }
      this.queryFrom.page = 1
      this.queryList()
    },
    changeTime(e) {
      if (e) {
        this.analyticalTopData.order_time = e
      }
    },
    disableClick() {
      if (this.disblesImg) {
        this.$Message.warning('当月已关账，不可操作库存')
        return
      }
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$api.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$api.deliveryProductList, { supplier_id: id }, false).then(res => {
        this.productList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$api.deliveryCodeSelect, { supplier_id: this.queryFrom.supplier_id, product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    dataBlur(e, testing) {
      if (testing && !e.target.value) return
      let res = this.$utils.dataType(e.target.value)
      if (res.code) {
        this.$Message.warning(res.text)
      }
    },
    // 选择仓库变化
    warehouseIdChange(val) {
      if (!val) {
        this.analyticalData = this.storageData
        return
      }
      let obj = {
        warehouse_id: val,
        line_info: this.storageData,
      }
      this.$http.post(this.$api.getdeliveryLookup, obj, false).then(res => {
        this.analyticalData = []
        this.analyticalData = res.data
        // 回显日期
        this.analyticalData.forEach((item, index) => {
          if (!item.batch_number) {
            this.$Message.warning(`第${index + 1}组数据没有批号`)
          }
          // item.licence_valid_period = item.licence_valid_period ? this.$moment(item.licence_valid_period * 1000).format('YYYY-MM-DD') : null
          if (typeof item.production_date == 'number') {
            item.production_date = item.production_date ? this.$moment(item.production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : null
            item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
          }
          if (typeof item.valid_period == 'number') {
            item.valid_period = item.valid_period ? this.$moment(item.valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
            item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
          }
        })
      })
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$api.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$api.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `${this.$api.warehouseDeliveryDetail.url}?id=${this.clickId}`
      // for (let objName in this.queryFrom) {
      //   if (this.queryFrom[objName]) {
      //     api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
      //   }
      // }
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$api.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = `${this.billNumber}.xlsx`
        aLink.click()

        // 打印完刷新列表
        this.queryList()
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 合并导出
    exportMerge() {
      let data = JSON.parse(JSON.stringify(this.queryFrom))
      delete data.page
      delete data.rows
      this.Loading = true
      this.$http.downFile(this.$api.deliveryBatchExport, data).then(res => {
        this.Loading = false
        let blob = new Blob([res.data], { type: 'application/zip' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '出库单记录合并导出表.zip'
        aLink.click()
      })
    },
    // 导出
    output(order_number) {
      this.$http.downFile(this.$api.warehouseDeliveryExport, { order_number: order_number }, true).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '出库单记录导出表.xlsx'
        aLink.click()
      })
    },
    up_is_back_proof(param){
      console.log(param.row);
      
      let params={
        id:param.row.id,
      }
      if(param.row.is_back_proof==1){
        params.is_back_proof=2;
      }
      if(param.row.is_back_proof==2){
        params.is_back_proof=1;
      }
      this.$http.put(this.$api.warehouseDelivery, params, false).then(res => {
        if(res.status){
          this.listData.map(item=>{
            if(item.id==params.id){
              if(param.row.is_back_proof==1){
                item.is_back_proof=2;
              }
              if(param.row.is_back_proof==2){
                item.is_back_proof=1;
              }
            }
          })
        }
        console.log('更新成功');
        //this.queryList(true);
      })
    },
    // 确认导入
    sureAnalytical() {
      if (this.loadingFlag) {
        return
      }
      // 校验
      if (!this.analyticalTopData.customer_name) {
        this.$Message.warning(`请完善 购买单位`)
        return
      }

      if (!this.analyticalTopData.warehouse_id) {
        this.$Message.warning(`请完善 仓库信息`)
        return
      }
      if (!this.analyticalTopData.sale_by) {
        this.$Message.warning(`请选择销售人`)
        return
      }
      if (!this.analyticalTopData.order_time) {
        this.$Message.warning(`请选择订单日期`)
        return
      }
      // if (!this.analyticalTopData.order_number) {
      //   this.$Message.warning(`请完善 单据编号`)
      //   return
      // }
      // if (!this.analyticalTopData.note) {
      //   this.$Message.warning(`请完善 收货地址`)
      //   return
      // }
      if (!this.order_type) {
        this.$Message.warning(`请完善 订单类型`)
        return
      }
      if (this.analyticalData.length == 0) {
        this.$Message.warning('请至少添加一组产品再提交')
        return
      }
      for (let index = 0; index < this.analyticalData.length; index++) {
        if (!this.analyticalData[index].quantity && this.analyticalData[index].quantity == 0) {
          this.$Message.warning(`请完善第${index + 1}组产品的 数量`)
          return
        }
        if (!this.analyticalData[index].sale_tax_rate && this.analyticalData[index].sale_tax_rate != 0) {
          this.$Message.warning(`请完善第${index + 1}组产品的 销售税率`)
          return
        }
        if (this.analyticalData[index].production_date) {
          let resCode = this.$utils.dataType(this.analyticalData[index].production_date)
          if (resCode.code) {
            this.$Message.warning(resCode.text)
            return
          }
        }
        if (this.analyticalData[index].valid_period) {
          let resCode2 = this.$utils.dataType(this.analyticalData[index].valid_period)
          if (resCode2.code) {
            this.$Message.warning(resCode2.text)
            return
          }
        }
        if (!this.analyticalData[index].batch_number) {
          this.$Message.warning(`请完善第${index + 1}组产品的 批号`)
          return
        }
        if (!this.analyticalData[index].valid_period) {
          this.$Message.warning(`请完善第${index + 1}组产品的 有效期/失效期`)
          return
        }
      }
      let dataArr = []
      this.isZeroNotice = []
      // 当前面的判断都过了之后，要进行所有数据的单价是否为0的判断
      for (let index2 = 0; index2 < this.analyticalData.length; index2++) {
        if (!this.analyticalData[index2].unit_price) {
          dataArr.push({
            index: index2,
            // tips:'第'+(index2 + 1)+'组产品的'+(!this.analyticalData[index2].quantity ? '数量为0,' : '') + (!this.analyticalData[index2].unit_price ? '单价为0,' : '') + (!this.analyticalData[index2].sale_tax_rate ? '税率为0,' : '') + '是否提交?'
            tips: '第' + (index2 + 1) + '组产品的' + (!this.analyticalData[index2].unit_price ? '单价不能为0,' : '') + '请修改为0.01。',
          })
          if (index2 == this.analyticalData.length - 1) {
            for (let m = 0; m < dataArr.length; m++) {
              this.isZeroNotice.push(dataArr[m].tips)
            }
            this.isZero = true
            return
          }
        }
      }
      this.loadingFlag = true
      // this.analyticalTopData.line_info = this.analyticalData
      this.analyticalTopData.order_time = this.analyticalTopData.order_time ? this.$moment(this.analyticalTopData.order_time).format('YYYY-MM-DD') : null
      let data = this.analyticalTopData
      data.order_type = this.order_type
      data.line_info = this.analyticalData
      this.$http.post(this.$api.deliveryOrderImport, data, true).then(res => {
        if (res.message != 'success') {
          this.$Message.warning({
            background: true,
            content: res.message,
            closable: true,
            duration: 3,
          })
          this.loadingFlag = false
        } else {
          this.$Message.success({
            background: true,
            content: '生成出库单成功',
            closable: true,
            duration: 2,
          })
          this.loadingFlag = false
          this.analytical = false
          this.queryList()
        }
      })
    },
    sureIsZero() {
      this.isZero = false
      this.analyticalTopData.order_time = this.analyticalTopData.order_time ? this.$moment(this.analyticalTopData.order_time).format('YYYY-MM-DD') : null
      let data = this.analyticalTopData
      data.order_type = this.order_type
      data.line_info = this.analyticalData
      this.$http.post(this.$api.deliveryOrderImport, data, true).then(res => {
        if (res.message != 'success') {
          this.$Message.warning({
            background: true,
            content: res.message,
            closable: true,
            duration: 3,
          })
        } else {
          this.$Message.success({
            background: true,
            content: '生成出库单成功',
            closable: true,
            duration: 2,
          })
          this.analytical = false
          this.queryList()
        }
      })
    },
    // 修改记录
    changeInput(e, field, index) {
      if (typeof e == 'object' && e) {
        e = e.target.value
      }
      function foo(e, that) {
        if (e * 1 < 0) {
          // that.analyticalData[index][field] = 1
          that.$set(that.analyticalData[index], field, 1)
        } else {
          that.analyticalData[index][field] = e.trim() * 1
        }
        // 计算金额
        if (isNaN(that.analyticalData[index].quantity) || isNaN(that.analyticalData[index].unit_price)) {
          that.analyticalData[index].amount = 0
        } else {
          that.analyticalData[index].amount = '¥' + (that.analyticalData[index].quantity * that.analyticalData[index].unit_price).toFixed(2)
        }
      }
      switch (field) {
        case 'quantity': // 数量
          this.$set(this.analyticalData[index], field, e * 1)
          let total = this.analyticalData[index].quantity * this.analyticalData[index].unit_price
          this.$set(this.analyticalData[index], 'amount', total > 0 && total ? '¥' + total.toFixed(2) : '¥' + 0)
          break
        case 'unit_price': // 单价
          this.$set(this.analyticalData[index], field, e * 1)
          if (e) {
            this.$nextTick(() => {
              this.analyticalData[index].unit_price = Number(e.toFixed(2))
            })
          }
          let total2 = this.analyticalData[index].quantity * this.analyticalData[index].unit_price
          this.$set(this.analyticalData[index], 'amount', total2 > 0 && total2 ? '¥' + total2.toFixed(2) : '¥' + 0)
          this.analyticalData[index][field] = e
          break
        case 'sale_tax_rate': // 销售税率
          if (e || e == 0) {
            this.$set(this.analyticalData[index], field, e * 1)
          } else {
            this.$set(this.analyticalData[index], field, null)
          }
          break
        // case 'unit_price': // 单价
        // case 'amount': // 金额
        //   foo(e, this)
        //   break;
        case 'production_date': // 生产日期
        case 'valid_period': // 有效期/失效期
        case 'licence_valid_period': // 注册证有效期

        default:
          this.analyticalData[index][field] = e.trim()
          break
      }
    },
    // 删除记录
    delAnalytical(index) {
      if (this.analyticalData.length == 1) {
        this.$Message.warning('至少保留一个产品')
        return
      }
      this.analyticalData.splice(index, 1)
    },
    // 新增记录
    addAnalytical(index) {
      this.analyticalData.push(JSON.parse(JSON.stringify(this.analyticalData[index])))
    },
    // 模板导入
    importTemplate(res) {
      this.analytical = true
      this.analyticalTopData = {
        customer_name: res.customer_name,
        order_number: res.order_number,
        note: res.note,
        warehouse_id: res.warehouse_id,
        warehouse_name: res.warehouse_name,
      }
      this.analyticalData = res.line_info
      this.analyticalTopData.order_time = this.$moment(new Date()).format('YYYY-MM-DD')
      // 回显日期
      this.analyticalData.forEach((item, index) => {
        item.unit_price = item.unit_price ? Number(item.unit_price.toFixed(2)) : 0
        // 默认为13税率
        item.sale_tax_rate = item.sale_tax_rate ? item.sale_tax_rate : 13
        item.licence_valid_period = item.licence_valid_period ? this.$moment(item.licence_valid_period * 1000).format('YYYY-MM-DD') : null
        item.production_date = item.production_date ? this.$moment(item.production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : null
        item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
        item.valid_period = item.valid_period ? this.$moment(item.valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
        item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
      })
      // 暂存数据数据
      this.storageData = JSON.parse(JSON.stringify(this.analyticalData))
    },
    // 模板下载
    downTemplate() {
      this.$http.downFile(this.$api.deliveryTemplateDownload, true).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '出库单导入模板.xlsx'
        aLink.click()
      })
    },

    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    // 反冲
    recoil(id) {
      this.$http.get(this.$api.warehouseDeliveryRecoil, { id: id }, true).then(res => {
        if (res.status) {
          this.$Message.success('反冲成功')
          this.queryList()
        }
      })
      this.isConfirm = [false, undefined]
    },
    // 改变日期
    changeDate(e) {
      this.queryFrom.create_time_start = e[0]
      this.queryFrom.create_time_end = e[1]
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 客户名称改变
    customerChange(e) {
      this.queryFrom.customer_type = ''
      // 判断客户类型
      if (e) {
        this.customerList.forEach(item => {
          if (item.customer_id == e.value && item.customer_name == e.label) {
            this.queryFrom.customer_type = item.customer_type
          }
        })
      } else {
        this.queryFrom.customer_type = ''
      }
    },
    // 客户名称下拉
    getCustomerList() {
      this.$http.get(this.$api.customerList).then(res => {
        this.customerList = res.data
      })
    },
    // 出库仓库下拉
    getWarehouseList() {
      this.$http.get(this.$api.permissionWareHouseList, { page: 1, rows: 1000 }).then(res => {
        this.warehouseList = res.data
      })
    },
    // 经手人下拉
    getUserList() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: '1' }).then(res => {
        this.userList = res.data
      })
    },
    // 销售人下拉
    getPublicSpecialUser() {
      this.$http.get(this.$api.getPublicSpecialUser, { user_type: '2' }).then(res => {
        this.PublicSpecialUser = res.data
      })
    },
    // 查询列表数据
    queryList(query = false) {
      this.chaxunFlag = false
      this.tabloading = true
      if (query) {
        this.queryFrom.page = 1
      }
      if (this.queryFrom.product_code_array) {
        this.queryFrom.specification_id_str = this.queryFrom.product_code_array.join(',')
      } else {
        this.queryFrom.specification_id_str = ''
      }
      if (this.queryFrom.product_id) {
        this.productList.forEach(item => {
          if (item.product_id == this.queryFrom.product_id) {
            this.queryFrom.product_name = item.product_name
          }
        })
      } else {
        this.queryFrom.product_name = ''
      }
      if (this.sort_type) {
        ;(this.queryFrom.sort_type = this.sort_type), (this.queryFrom.sort_field = this.sort_field)
      } else {
        ;(this.queryFrom.sort_type = ''), (this.queryFrom.sort_field = '')
      }
      this.$http.get(this.$api.warehouseDelivery, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data
        this.total_amount = res.total_amount
        this.total_number = res.total_number
        this.listData.forEach(item => {
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD') : ''
          this.$set(item, 'jujiao', true)
        })
        this.total = res.total
      })
    },
    clickBeiZhu(index, id, note){
      this.rowWarehouse_receipt_id = id
      this.listDataIndex = index
      this.listDataNote = note
      this.changeNote = JSON.parse(JSON.stringify(note))
      this.$set(this.listData[index], 'jujiao', false)
      this.$set(this.listData[index], 'note', this.listDataNote)
      let data = 'input' + id
      setTimeout(() => {
        this.$refs[data].$el.children.forEach(item => {
          if (item.nodeName == 'INPUT') {
            this.$nextTick(() => {
              document.getElementsByClassName('ivu-input ivu-input-default')[3].focus()
            })
          }
        }, 500)
      })
    },
    changeNoteFun() {
      if (this.listDataNote == this.changeNote) {
        this.$set(this.listData[this.listDataIndex], 'jujiao', true)
        return
      }
      this.$http
        .put(this.$api.warehouseDelivery, {
          id: this.rowWarehouse_receipt_id,
          note: this.changeNote ? this.changeNote : '',
        })
        .then(res => {
          if (res.status) {
            this.$Message.success('修改成功')
            this.queryList()
          }
        })
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsWarehouseReceiptRecord',
        query: {
          id: row.id,
          recoil_status: row.recoil_status,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}

// 反冲确认弹窗
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}

// 导入确认弹窗
.modal {
  .formMarginBtm10 {
    .ivu-form-item {
      margin-bottom: 10px !important;
    }
  }

  .money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding-right: 8px;
    }
  }

  .foot {
    text-align: right;
    margin-top: 20px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.marginLeft20 {
  margin-left: 20px;
}
.marginLeft10 {
  margin-left: 10px;
}
.iviewIptWidth200 {
  width: 200px;
}
.iviewIptWidth180 {
  width: 180px;
}
.margin0 {
  margin: 0px;
}
.overF {
  max-height: 400px;
  overflow-y: auto;
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
.changeClass {
  cursor: pointer;
}
</style>
